import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import helper from 'helpers'

const ConsentReportPdf = ({
  exportingPdf = false,
  consentForms = [],
  shouldCreateVersion = false,
  refresh = () => {},
  setExportingPdf = () => {},
  reportSelected,
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo, customPalette } = useCustomTheme()
  const { userCompany } = useAuth()

  const customStyles = customTheme?.palette?.layout
  const customThemeStatus = customPalette?.status

  useEffect(() => {
    if (exportingPdf) {
      if (!isEmpty(consentForms)) {
        helper.reports.consent.exportPdf(
          userCompany,
          customStyles,
          customLogo,
          customThemeStatus,
          consentForms,
          snackbar,
          shouldCreateVersion,
          refresh,
          setExportingPdf,
          reportSelected,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingPdf])
  return <></>
}

export default ConsentReportPdf
