import palette from 'theme/palette'

import { fontSize, PADDING } from '../constants'

const subtitle = (
  pdf,
  sub,
  positionY,
  textColor = palette.primary.main,
  maxWidth = 345,
) => {
  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(fontSize.subtitle)
  pdf.setTextColor(textColor)

  const lines = pdf.splitTextToSize(sub, maxWidth)

  lines.forEach((line, index) => {
    pdf.text(
      line,
      PADDING,
      positionY + index * fontSize.subtitle,
      null,
      null,
      'left',
    )
  })

  const totalHeight = lines.length * fontSize.subtitle * 1.2

  return positionY + totalHeight
}

export default subtitle
