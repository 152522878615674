import { jsPDF } from 'jspdf'

import moment from 'moment'

import pages from './pages'
import templates from '../templates'

import constants from 'constants/index'
import * as service from 'service'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Regular-normal'
import '../fonts/Roboto-Bold-normal'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async (
  userCompany,
  customStyles,
  customLogo,
  customThemeStatus,
  users,
  setExportingPdf,
  snackbar,
  shouldCreateVersion,
  refresh,
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  try {
    templates.firstCover(
      pdf,
      userCompany?.company?.name,
      userCompany?.company?.cpf || userCompany?.company?.cnpj,
      dateNow,
      customStyles,
      customLogo,
      'Relatório de\nTreinamento',
    )

    await pages.training({
      pdf,
      date: `Treinamentos | ${dateNow}`,
      customThemeStatus,
      users,
    })

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName = `Relatório_de_Treinamentos_${userCompany?.company?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}_${dateNow}.pdf`

    pdf.save(reportName)

    if (shouldCreateVersion) {
      const file = new File([pdf.output('blob')], reportName)
      await service.dponet.reportVersions.create({
        file,
        name: 'Relatório de Treinamentos',
        kind: constants.reportVersions.KIND.TRAINING,
      })
      refresh()
    }

    snackbar.open({
      variant: 'success',
      message: 'Relatório gerado com sucesso, aguarde o download começar',
    })
  } catch (error) {
    console.error(error)
    snackbar.open({
      variant: 'error',
      message: 'Ocorreu um erro ao gerar o relatório',
    })
  } finally {
    setExportingPdf(false)
  }
}

export default exportPdf
