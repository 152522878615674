import { jsPDF } from 'jspdf'

import moment from 'moment'

import pages from './pages'
import templates from '../templates'

import constants from 'constants/index'
import * as service from 'service'
import * as Sentry from '@sentry/react'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Regular-normal'
import '../fonts/Roboto-Bold-normal'

import helpers from 'helpers'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async ({
  customLogo,
  customPalette,
  customStyles,
  fragilityStats,
  gapStats,
  generalStats,
  processStats,
  questionnaires,
  refresh,
  setExportingPdf,
  setLoading,
  shouldCreateVersion,
  snackbar,
  supplierStats,
  userCompany,
}) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  try {
    setLoading(true)

    templates.firstCover(
      pdf,
      userCompany?.company?.name,
      userCompany?.company?.cpf || userCompany?.company?.cnpj,
      dateNow,
      customStyles,
      customLogo,
      'Relatório Gerencial da Adequação à LGPD \nVisão Geral',
    )

    if (!customPalette?.status) {
      pages.disclaimer(pdf, dateNow, customStyles)
    }

    pages.summary(pdf)

    pages.introduction(
      pdf,
      `Introdução | ${dateNow}`,
      customStyles,
      customPalette?.status,
    )

    await pages.generalInformations(
      pdf,
      `Visão Geral | ${dateNow}`,
      generalStats,
      gapStats,
      processStats,
      supplierStats,
      questionnaires,
      customStyles,
      fragilityStats,
      customPalette?.status,
    )

    await pages.processes(
      pdf,
      `Processos | ${dateNow}`,
      processStats,
      customStyles,
      customPalette?.status,
    )

    await pages.diagnostics({
      date: `Diagnóstico | ${dateNow}`,
      pdf,
      gapStats,
      customStyles,
      fragilityStats,
      customThemeStatus: customPalette?.status,
    })

    await pages.incidents(
      pdf,
      `Incidentes | ${dateNow}`,
      generalStats,
      customStyles,
      customPalette?.status,
    )

    await pages.suppliers(
      pdf,
      `Fornecedores | ${dateNow}`,
      supplierStats,
      customStyles,
      customPalette?.status,
    )

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName = `Relatório_gerencial_${userCompany?.company?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}_${dateNow}.pdf`

    pdf.save(reportName)

    if (shouldCreateVersion) {
      const file = new File([pdf.output('blob')], reportName)

      await service.dponet.reportVersions.create({
        file,
        name: 'Relatório Gerencial',
        kind: constants.reportVersions.KIND.MANAGEMENT,
      })

      refresh()
    }

    snackbar.open({
      variant: 'success',
      message: 'Relatório gerado com sucesso, aguarde o download começar',
    })
  } catch (error) {
    console.error(error)

    snackbar.open({
      variant: 'error',
      message:
        helpers.formatters.errorMessage(error) ||
        'Ocorreu um erro ao gerar o relatório',
    })
    Sentry.captureException(error)
  } finally {
    setExportingPdf(false)
    setLoading(false)
  }
}

export default exportPdf
