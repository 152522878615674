import { sum } from 'lodash'

import palette from 'theme/palette'

import helpers from 'helpers'
import constants from 'constants/index'

import templates from '../../templates'
import components from '../../components'
import dashboardComponents from '../components'

import {
  calculateAspectRatioFit,
  calculateTotalIncidents,
  convertObjectToCanvas,
  ratio,
} from '../../helpers'

import {
  WIDTH as WIDTHPdf,
  PADDING,
  GAP,
  rectangleSize,
  fontSize,
} from '../../constants'

const generalInformations = async (
  pdf,
  date,
  generalStats,
  GAPStats,
  processStats,
  supplierStats,
  questionnaires,
  customStyles,
  fragilityStats,
  customThemeStatus,
) => {
  const WIDTH = WIDTHPdf(pdf)
  const skipedPages = customThemeStatus ? 1 : 3

  templates.chapterCover(
    pdf,
    'VISÃO GERAL',
    'Síntese das principais informações e métricas apresentadas no painel de controle.',
    customStyles,
  )

  const { securityMeasures: securityMeasuresIds, fragilities: fragilitiesIds } =
    constants.myLgpd.GRAPH_IDS

  const totalAnswered = sum(
    GAPStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.asweredQuestions || 0,
    ),
  )
  const totalQuestion = sum(
    GAPStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.totalQuestions || 0,
    ),
  )
  const pendings = totalQuestion - totalAnswered

  var departmentsByProcessesesDisapproved = processStats?.departments
    ?.sort((a, b) => {
      const sumA = a.statuses.disapproved
      const sumB = b.statuses.disapproved
      return sumB - sumA
    })
    .slice(0, 4)

  departmentsByProcessesesDisapproved = departmentsByProcessesesDisapproved.map(
    (department) => [department?.name, department?.statuses?.disapproved],
  )

  var departmentsByProcessesesPending = processStats?.departments
    ?.sort((a, b) => {
      const sumA = a.statuses.pending
      const sumB = b.statuses.pending
      return sumB - sumA
    })
    .slice(0, 4)

  departmentsByProcessesesPending = departmentsByProcessesesPending.map(
    (department) => [department?.name, department?.statuses?.pending],
  )

  pdf.addPage()
  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '2', 'Visão geral')

  components.paragraph(
    pdf,
    ' Essa seção traz os principais indicadores, como um resumo dos principais dados que estão contidos no relatório.',
    GAP * 3,
    palette.subscription.primary.main,
  )

  components.subtitle(pdf, 'Quantidade de departamentos e usuários', GAP * 3.75)

  components.paragraph(
    pdf,
    ' Quantidade de departamentos contidos dentro da empresa e quantidade de usuários dentro do sistema de adequação à LGPD.',
    GAP * 4,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 4.5,
    rectangleSize.halfWidth,
    palette.primary.main,
    ratio,
    ['Quantidade de departamentos'],
    generalStats.quantityDepartments.toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.rectangle(
    pdf,
    WIDTH / 2 + ratio(70),
    GAP * 4.5,
    rectangleSize.halfWidth,
    palette.primary.main,
    ratio,
    ['Quantidade de usuários'],
    generalStats.quantityUsers.toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.subtitle(pdf, 'Processos', GAP * 6.25)

  components.paragraph(
    pdf,
    ' Processos são sequências de etapas, procedimentos internos ou ações realizadas pelas organizações para o tratamento de dados pessoais.',
    GAP * 6.5,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 7,
    rectangleSize.halfWidth,
    palette.statuses.approved,
    ratio,
    'Processos aprovados',
    helpers.myLgpd.approvedProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.rectangle(
    pdf,
    WIDTH / 2 + ratio(70),
    GAP * 7,
    rectangleSize.halfWidth,
    palette.statuses.disapproved,
    ratio,
    'Processos reprovados',
    helpers.myLgpd.reprovedProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.numberPage(pdf, palette.black, false, skipedPages)

  pdf.addPage()
  components.lineWithDate(pdf, date)

  components.subtitle(
    pdf,
    ['Departamentos que possuem mais processos', 'pendentes'],
    GAP,
  )

  components.paragraph(
    pdf,
    ' Departamentos que precisam cadastrar seus processos e enviá-los para revisão.',
    GAP * 1.5,
    palette.subscription.primary.main,
  )

  components.table(
    pdf,
    [['Departamentos', 'Total']],
    departmentsByProcessesesPending,
    GAP * 1.75,
  )

  components.subtitle(
    pdf,
    ['Departamentos que mais possuem processos', 'reprovados'],
    pdf.lastAutoTable.finalY + GAP / 2,
  )

  components.paragraph(
    pdf,
    ' Departamentos que precisam implementar as melhorias que estão pendentes.',
    pdf.lastAutoTable.finalY + GAP,
    palette.subscription.primary.main,
  )

  components.table(
    pdf,
    [['Departamentos', 'Total']],
    departmentsByProcessesesDisapproved,
    pdf.lastAutoTable.finalY + GAP * 1.25,
  )

  components.subtitle(pdf, 'Questionários', pdf.lastAutoTable.finalY + GAP / 2)

  components.paragraph(
    pdf,
    ' Formularios usados para avaliar o nivel de conformidade da organização em relação às diretrizes de proteção de ddos estipuladas pela lgpd e as boas praticas da iso.',
    pdf.lastAutoTable.finalY + GAP / 1.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    pdf.lastAutoTable.finalY + GAP * 1.25,
    ratio(720),
    palette.dashboard.chart.primary.approved,
    ratio,
    'Questionários respondidos',
    totalAnswered.toString(),
  )
  components.rectangle(
    pdf,
    PADDING * 5,
    pdf.lastAutoTable.finalY + GAP * 1.25,
    ratio(720),
    palette.statuses.disapproved,
    ratio,
    'Questionários não respondidos',
    pendings.toString(),
  )

  components.numberPage(pdf, palette.black, false, skipedPages)

  pdf.addPage()
  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'Conformidade por questionário', GAP)

  components.paragraph(
    pdf,
    ' Nível de conformidade em relação às diretrizes de proteção de dados por categoria de questionário.',
    GAP * 1.25,
    palette.subscription.primary.main,
  )

  let currentY = GAP * 1.75

  var complianceByQuestionnaires =
    helpers.myLgpd.mountComplianceRows(questionnaires)

  complianceByQuestionnaires.forEach((complianceQuestion, index) => {
    currentY = GAP * 1.75 + ratio(80 * index)

    dashboardComponents.progressBar(
      pdf,
      currentY,
      complianceQuestion?.percentage,
      complianceQuestion?.name,
    )
  })

  currentY = currentY + GAP / 2

  components.subtitle(pdf, 'Medidas de Segurança', currentY)

  currentY = currentY + 16

  components.paragraph(
    pdf,
    ' Nível de conformidade em relação às diretrizes de proteção de dados por categoria de questionário.',
    currentY,
    palette.subscription.primary.main,
  )

  currentY = currentY + 16

  components.rectangle(
    pdf,
    PADDING,
    currentY,
    WIDTH - PADDING * 2,
    palette.primary.main,
    ratio,
    'Total de medidas adotadas',
    fragilityStats?.securityMeasures.toString(),
  )

  currentY = currentY + rectangleSize.height + 16

  components.text({
    pdf,
    sub: 'Status de medidas por categoria',
    positionY: currentY,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  currentY = currentY + 8

  const securityMeasureByCategory = await convertObjectToCanvas(
    `apexcharts${securityMeasuresIds.statusesByCategories}`,
  )

  const securityMeasureByCategoryProportions = calculateAspectRatioFit(
    securityMeasureByCategory.width,
    securityMeasureByCategory.height,
    WIDTH - PADDING * 2,
    165,
  )

  pdf.addImage(
    securityMeasureByCategory.toDataURL('image/png'),
    'PNG',
    PADDING,
    currentY,
    securityMeasureByCategoryProportions.width,
    securityMeasureByCategoryProportions.height,
  )

  components.numberPage(pdf, palette.black, false, skipedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'Ameaças', GAP)

  components.paragraph(
    pdf,
    'Nível de conformidade em relação às diretrizes de proteção de dados por categoria de questionário',
    GAP * 1.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 1.75,
    WIDTH - PADDING * 2,
    palette.primary.main,
    ratio,
    'Total de ameaças',
    fragilityStats?.fragility?.totalCount.toString(),
  )

  const fragilitiesWithLinkedMeasures = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.withSecurityMeasures}`,
  )

  const fragilitiesWithLinkedMeasuresProportions = calculateAspectRatioFit(
    fragilitiesWithLinkedMeasures.width,
    fragilitiesWithLinkedMeasures.height,
    (WIDTH - PADDING * 2) / 2,
    165,
  )

  pdf.addImage(
    fragilitiesWithLinkedMeasures.toDataURL('image/png'),
    'PNG',
    PADDING / 2,
    GAP * 3.42,
    fragilitiesWithLinkedMeasuresProportions.width,
    fragilitiesWithLinkedMeasuresProportions.height,
  )

  components.text({
    pdf,
    sub: 'Ameaças com medidas vinculadas',
    positionY: GAP * 3.25,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const fragilitiesBySeverity = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.bySeverities}`,
  )

  const fragilitiesBySeverityProportions = calculateAspectRatioFit(
    fragilitiesBySeverity.width,
    fragilitiesBySeverity.height,
    (WIDTH - PADDING * 2) / 2,
    165,
  )

  components.text({
    pdf,
    sub: ['Distribuição de ameaças por grau', 'de severidade'],
    positionY: GAP * 3.25,
    positionX: fragilitiesWithLinkedMeasuresProportions.width + PADDING,
    customFontSize: fontSize.paragraph,
  })

  pdf.addImage(
    fragilitiesBySeverity.toDataURL('image/png'),
    'PNG',
    fragilitiesWithLinkedMeasuresProportions.width + PADDING * 0.7,
    GAP * 3.45,
    fragilitiesBySeverityProportions.width,
    fragilitiesBySeverityProportions.height,
  )

  components.subtitle(pdf, 'Incidentes', GAP * 6.75)

  components.paragraph(
    pdf,
    'Incidentes se referem a qualquer evento ou ocorrência que resulte na violação ou comprometimento da segurança de dados pessoais.',
    GAP * 7,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 7.5,
    WIDTH - PADDING * 2,
    palette.primary.main,
    ratio,
    'Total de incidentes',
    calculateTotalIncidents(generalStats?.incidentRmcs),
  )

  components.numberPage(pdf, palette.black, false, skipedPages)
}

export default generalInformations
