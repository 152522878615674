import helpers from 'helpers'

import templates from '../../templates'
import components from '../../components'
import dashboardComponents from '../components'

import { WIDTH as WIDTHPdf, GAP, PADDING, rectangleSize } from '../../constants'

import {
  ratio,
  convertObjectToCanvas,
  calculateAspectRatioFit,
} from '../../helpers'

import palette from 'theme/palette'
import constants from 'constants/index'

const processes = async (
  pdf,
  date,
  processStats,
  customStyles,
  customThemeStatus,
) => {
  const skippedPages = customThemeStatus ? 1 : 3

  const WIDTH = WIDTHPdf(pdf)

  const { process: processIds, fragilities: fragilitiesIds } =
    constants.myLgpd.GRAPH_IDS

  templates.chapterCover(
    pdf,
    'PROCESSOS',
    'Síntese das principais informações e atividades que utilizem um dado pessoal na execução da sua operação',
    customStyles,
  )

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '3', 'Processos')

  components.paragraph(
    pdf,
    'De acordo com a Lei Geral de Proteção de Dados (LGPD), os processos são sequências de etapas ou ações realizadas pelas organizações para o tratamento de dados pessoais. Esses processos devem seguir os princípios estabelecidos pela lei, como finalidade, transparência e segurança, e garantir os direitos dos titulares dos dados.\n\nÉ essencial que as organizações implementem políticas e procedimentos para assegurar a conformidade com a LGPD em todas as fases do tratamento de dados, desde a coleta até a exclusão adequada, protegendo assim a privacidade e os direitos individuais.',
    GAP * 3.25,
    palette.subscription.primary.main,
  )

  components.subtitle(pdf, 'Processos ativos e inativos', GAP * 5)

  components.paragraph(
    pdf,
    'Os processos ativos são aqueles que estão em execução atualmente, enquanto os processos inativos são aqueles que não estão mais em operação ou foram concluídos.\n\nO estado de um processo pode variar ao longo do tempo, à medida que novos processos são implementados, outros são encerrados ou quando ocorrem alterações nas necessidades e operações da organização.',
    GAP * 5.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 6.25,
    rectangleSize.halfWidth,
    palette.statuses.approved,
    ratio,
    'Processos ativos',
    helpers.myLgpd.activeProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 6.25,
    rectangleSize.halfWidth,
    palette.text.mainMenu,
    ratio,
    'Processos inativos',
    helpers.myLgpd.inactiveProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.subtitle(pdf, 'Processos aprovados', 8 * GAP)

  components.paragraph(
    pdf,
    'Processos aprovados são aqueles que passaram por revisão e foram autorizados por nossos especialistas em LGPD. Eles foram considerados adequados e conformes com as políticas e regulamentos estabelecidos.\n\nA aprovação indica que esses processos são confiáveis, estão em conformidade e podem abranger uma ampla gama de atividades, desde procedimentos operacionais até políticas de segurança e gerenciamento de riscos. A aprovação dos processos é fundamental para garantir a eficiência, a consistência e a conformidade das operações da organização.',
    GAP * 8.25,
    palette.subscription.primary.main,
  )

  components.numberPage(pdf, palette.black, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.rectangle(
    pdf,
    PADDING,
    GAP * 0.75,
    rectangleSize.halfWidth,
    palette.statuses.approved,
    ratio,
    'Processos aprovados',
    helpers.myLgpd.approvedProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.subtitle(pdf, 'Processos reprovados', GAP * 2.5)

  components.paragraph(
    pdf,
    'Processos reprovados são aqueles que foram analisados e não receberam a aprovação necessária dos especialistas.\n\nEsses processos não atendem aos critérios estabelecidos, não estão em conformidade com as políticas, diretrizes ou regulamentos aplicáveis, ou apresentam deficiências significativas que impossibilitam sua implementação ou execução adequadas. A reprovação de um processo indica que ajustes, melhorias ou revisões são necessários antes que possa ser considerado apto para uso dentro da organização.',
    GAP * 2.75,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 4.25,
    rectangleSize.halfWidth,
    palette.statuses.disapproved,
    ratio,
    'Processos reprovados',
    helpers.myLgpd.reprovedProcess(processStats).toString(),
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.subtitle(pdf, 'Processos por risco', GAP * 6)

  components.paragraph(
    pdf,
    'O gráfico de processos classificados por risco é uma representação visual que categoriza os diferentes processos de uma organização com base nos níveis de risco associados a eles. No gráfico, os processos são agrupados em quatro categorias de risco: baixo, médio, alto e severo.\n\nOs processos classificados como de risco baixo representam atividades com menor probabilidade de causar impactos significativos na segurança e privacidade dos dados pessoais.\n\nOs processos de risco médio possuem um nível moderado de risco, exigindo maior atenção e medidas de proteção. Já os processos de risco alto indicam uma possibilidade maior de riscos e requerem a implementação de controles mais rigorosos.\n\nPor fim, os processos classificados como de risco severo são aqueles que apresentam o maior nível de risco, demandando atenção imediata e medidas de segurança robustas para minimizar qualquer impacto negativo nas informações pessoais. Esse gráfico auxilia na visualização e na priorização das atividades que exigem mais atenção e recursos para garantir a conformidade com a LGPD e proteção adequada dos dados.',
    GAP * 6.25,
    palette.subscription.primary.main,
  )

  components.numberPage(pdf, palette.black, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  const lowRiskCount = helpers.myLgpd.lowRiskProcess(processStats).toString()
  const mediumRiskCount = helpers.myLgpd
    .mediumRiskProcess(processStats)
    .toString()
  const highRiskCount = helpers.myLgpd.highRiskProcess(processStats).toString()
  const severeRiskCount = helpers.myLgpd
    .severeRiskProcess(processStats)
    .toString()

  dashboardComponents.progressBar(
    pdf,
    PADDING + ratio(GAP / 2),
    lowRiskCount,
    'Risco baixo',
    false,
  )
  dashboardComponents.progressBar(
    pdf,
    PADDING + ratio(GAP / 2 + 80),
    mediumRiskCount,
    'Risco médio',
    false,
  )
  dashboardComponents.progressBar(
    pdf,
    PADDING + ratio(GAP / 2 + 160),
    highRiskCount,
    'Risco alto',
    false,
  )
  dashboardComponents.progressBar(
    pdf,
    PADDING + ratio(GAP / 2 + 240),
    severeRiskCount,
    'Risco severo',
    false,
  )

  components.subtitle(pdf, 'Status dos processos', GAP * 2.5)

  components.paragraph(
    pdf,
    'O gráfico de processos classificados por status apresenta uma representação visual dos diferentes processos de uma organização com base em seu status atual. Existem cinco categorias de status que podem ser atribuídas aos processos: pendente, em revisão, aprovado, reprovado e inativo.\n\nOs processos pendentes são aqueles que estão aguardando avaliação ou aprovação. Os processos em revisão estão sendo analisados e passando por uma avaliação detalhada. Os processos aprovados receberam a devida aprovação e são considerados adequados para implementação. Os processos reprovados não atenderam aos critérios estabelecidos e requerem ajustes ou melhorias antes de poderem ser implementados. Por fim, os processos inativos não estão mais em operação ou foram concluídos. O gráfico auxilia na visualização rápida do status de cada processo, permitindo que a organização monitore e gerencie de forma eficiente o progresso e a conformidade de seus diferentes processos com a LGPD',
    GAP * 2.75,
    palette.subscription.primary.main,
  )

  const processesByStatus = await convertObjectToCanvas(
    `apexcharts${processIds.statuses}`,
  )

  const processesByStatusProportions = calculateAspectRatioFit(
    processesByStatus.width,
    processesByStatus.height,
    WIDTH - PADDING * 2,
    165,
  )

  pdf.addImage(
    processesByStatus.toDataURL('image/png'),
    'PNG',
    (WIDTH - PADDING * 2) / 3.5,
    GAP * 5.5,
    processesByStatusProportions.width,
    processesByStatusProportions.height,
  )

  components.numberPage(pdf, palette.black, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.subtitle(
    pdf,
    'Contribuição de cada departamento para o risco',
    GAP,
  )

  components.paragraph(
    pdf,
    'O gráfico de contribuição de cada departamento para o risco apresenta uma representação visual das contribuições de cada departamento de uma organização para os diferentes níveis de risco: baixo, médio, alto e severo. Cada departamento é representado por uma cor específica nas barras do gráfico, permitindo uma identificação visual rápida. As barras são proporcionais à contribuição de cada departamento para cada nível de risco. Isso auxilia na compreensão de quais departamentos têm um maior impacto nos diferentes níveis de risco e permite uma análise mais precisa da distribuição do risco em toda a organização.\n\nO gráfico fornece uma visão clara das áreas que possuem maior contribuição para os riscos mais significativos, permitindo que medidas de mitigação e controle sejam implementadas de forma direcionada e eficaz. Essa visualização auxilia na tomada de decisões estratégicas e na alocação de recursos para a gestão de riscos e a conformidade com a LGPD.',
    GAP * 1.25,
    palette.subscription.primary.main,
  )

  const departmentsHighestOfFragilities = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.departmentsWithHighestFragilities}`,
  )

  const departmentsHighestOfFragilitiesProportions = calculateAspectRatioFit(
    departmentsHighestOfFragilities.width,
    departmentsHighestOfFragilities.height,
    WIDTH - PADDING * 2,
    300,
  )

  pdf.addImage(
    departmentsHighestOfFragilities.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 3.5,
    departmentsHighestOfFragilitiesProportions.width,
    departmentsHighestOfFragilitiesProportions.height,
  )

  components.numberPage(pdf, palette.black, false, skippedPages)
}

export default processes
