import templates from '../../templates'
import components from '../../components'

import { GAP, PADDING, rectangleSize } from '../../constants'

import { ratio } from '../../helpers'

import palette from 'theme/palette'

const incidents = async (
  pdf,
  date,
  generalStats,
  customStyles,
  customThemeStatus,
) => {
  const skippedPages = customThemeStatus ? 1 : 3

  const incidentsOnPedingStatus = generalStats?.incidentRmcs?.pending || 0

  const incidentsOnFinalizedStatus = generalStats?.incidentRmcs?.finalized || 0

  templates.chapterCover(pdf, 'INCIDENTES', '', customStyles)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '5', 'Incidentes')

  components.paragraph(
    pdf,
    'Na LGPD, incidentes se referem a qualquer evento ou ocorrência que resulte naviolação ou comprometimento da segurança de dados pessoais. Os incidentes podemenvolver a divulgação não autorizada, acesso indevido, perda, roubo, alteração oudestruição de dados pessoais. Esses incidentes podem ocorrer devido a falhas desegurança, ataques cibernéticos, erros humanos, desastres naturais ou outrascircunstâncias adversas. Quando um incidente ocorre, a organização é obrigada a tomarmedidas imediatas para mitigar os impactos, notificar as autoridades e os titulares dosdados, e tomar ações corretivas para evitar incidentes semelhantes no futuro.\n\nA LGPD exige que as organizações tenham procedimentos claros e eficazes para lidarcom incidentes de segurança de dados e garantir a proteção adequada das informaçõespessoais.',
    GAP * 3.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 5.5,
    rectangleSize.halfWidth,
    palette.primary.main,
    ratio,
    'Incidentes em análise',
    incidentsOnPedingStatus.toString(),
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 5.5,
    rectangleSize.halfWidth,
    palette.primary.main,
    ratio,
    'Incidentes resolvidos',
    incidentsOnFinalizedStatus.toString(),
  )

  components.numberPage(pdf, palette.black, false, skippedPages)
}

export default incidents
