import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import helpers from 'helpers'

const TrainingPdf = ({
  exportingPdf,
  setExportingPdf,
  users,
  shouldCreateVersion,
  refresh,
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo, customPalette } = useCustomTheme()
  const { userCompany } = useAuth()

  const customStyles = customTheme?.palette?.layout
  const customThemeStatus = customPalette?.status

  useEffect(() => {
    if (exportingPdf) {
      if (users) {
        helpers.reports.training.exportPdf(
          userCompany,
          customStyles,
          customLogo,
          customThemeStatus,
          users,
          setExportingPdf,
          snackbar,
          shouldCreateVersion,
          refresh,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingPdf])

  return <></>
}

TrainingPdf.propTypes = {
  setExportingPdf: PropTypes.func,
  users: PropTypes.array,
}

TrainingPdf.defaultProps = {
  setExportingPdf: () => {},
}

export default TrainingPdf
