import React, { useState } from 'react'
import { routes } from 'Routes'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Typography, Button, Chip, Tooltip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { DeleteModal } from './components'

import helpers from 'helpers'

import constants from 'constants/index'

import styles from './styles'

const ReceiptTableRow = ({ receipt, refresh, ...rest }) => {
  const [openModal, setOpenModal] = useState(false)

  const history = useHistory()
  const classes = styles()

  const navigateAction = (consentReceiptId) =>
    history.push(
      reverse(routes.consent.consentReceiptDetails, {
        consentFormId: receipt?.consentForm?.id,
        consentReceiptId,
      }),
    )

  const handleOpenModal = () => setOpenModal((prevOpen) => !prevOpen)

  return (
    <>
      <TableRow {...rest}>
        <TableCell>
          <Typography className={classes.rowText}>{receipt?.id}</Typography>
        </TableCell>
        <TableCell>
          <Tooltip title={receipt?.consentForm?.title} placement="top-start">
            <Typography className={classes.rowText}>
              {receipt?.consentForm?.title}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {receipt?.userEmail}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {helpers.formatters.date(receipt?.createdAt) || '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            className={
              receipt?.status === constants.consent.AUTHORIZED_CONSENT
                ? classes.statusLabelAuthorized
                : classes.statusLabelRevoked
            }
            label={constants.consent.STATUS_LABEL_RECEIPT[receipt?.status]}
          />
        </TableCell>
        <TableCell align="right">
          <MenuButton>
            <Button
              color="secondary"
              onClick={() => navigateAction(receipt?.id)}
              fullWidth
              size="small"
            >
              Ver detalhes
            </Button>
            <Permitted tag={constants.permissions.CONSENT_RECEIPTS.DESTROY}>
              <Button
                color="secondary"
                onClick={handleOpenModal}
                fullWidth
                size="small"
              >
                Excluir
              </Button>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
      <DeleteModal
        consentFormId={receipt?.consentForm?.id}
        receiptId={receipt?.id}
        open={openModal}
        setOpen={setOpenModal}
        refresh={refresh}
      />
    </>
  )
}

const ReceiptTable = ({ receipts, refresh }) => {
  const classes = styles()

  return (
    <>
      <Box width="100%">
        <PerfectScrollbar>
          <Table size="small" emptyMessage="Nenhum recibo encontrado" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="15%">
                  <Box my={1}>
                    <Typography className={classes.headText}>ID</Typography>
                  </Box>
                </TableCell>
                <TableCell width="20%">
                  <Typography className={classes.headText}>
                    Formulário
                  </Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography className={classes.headText}>E-mail</Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography className={classes.headText}>
                    Data do consentimento
                  </Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography className={classes.headText}>Status</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.headText}>Ações</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipts?.map((receipt) => (
                <ReceiptTableRow
                  receipt={receipt}
                  key={receipt?.id}
                  refresh={refresh}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

ReceiptTableRow.propTypes = {
  receipt: PropTypes.object,
  refresh: PropTypes.func,
}

ReceiptTableRow.defaultProps = {
  refresh: () => {},
}

ReceiptTable.propTypes = {
  receipts: PropTypes.object,
  refresh: PropTypes.func,
}

ReceiptTable.defaultProps = {
  refresh: () => {},
}

export default ReceiptTable
