const JUSTIFICATION = [
  {
    name: 'Alto risco',
    description:
      'O RIPD foi gerado visando descrever e gerir os processos de tratamento de dados pessoais que podem gerar altos riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco, nos termos do arts. 5º, inciso XVII e 6º, inciso X, da LGPD.',
  },
  {
    name: 'Legítimo Interesse',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença do fundamento do Legítimo Interesse no presente processo, observados os segredos comercial e industrial, nos termos do art. 10, § 3º, da LGPD.',
  },
  {
    name: 'Poder Público',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos agentes do Poder Público a publicação do RIPD, nos termos do art. 32 da LGPD.',
  },
  {
    name: 'Dados pessoais sensíveis',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença de dados pessoais sensíveis neste processo, nos termos do art. 38, da LGPD.',
  },
  {
    name: 'Princípio da segurança e da prevenção',
    description:
      'O controlador gerou o presente RIPD, visando o cumprimento dos princípios da segurança e da prevenção, nos termos do art. 6º, VII e VIII, da LGPD, a fim de implementar programa de governança em privacidade que, entre outros itens, deve estabelecer políticas e salvaguardas adequadas com base em processo de avaliação sistemática de impactos e riscos à privacidade, conforme determinação do art. 50, § 2º, I, d,d a LGPD.',
  },
  {
    name: 'Operações de tratamento efetuadas para fins exclusivos de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos responsáveis por operações de tratamento para fins de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais, a emissão do RIPD, nos termos do art. 4º,  § 3º, da LGPD.',
  },
]

const JUSTIFICATION_LABEL = {
  'Alto risco':
    'O RIPD foi gerado visando descrever e gerir os processos de tratamento de dados pessoais que podem gerar altos riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco, nos termos do arts. 5º, inciso XVII e 6º, inciso X, da LGPD.',
  'Legítimo Interesse':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença do fundamento do Legítimo Interesse no presente processo, observados os segredos comercial e industrial, nos termos do art. 10, § 3º, da LGPD.',

  'Poder Público':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos agentes do Poder Público a publicação do RIPD, nos termos do art. 32 da LGPD.',

  'Dados pessoais sensíveis':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença de dados pessoais sensíveis neste processo, nos termos do art. 38, da LGPD.',
  'Princípio da segurança e da prevenção':
    'O controlador gerou o presente RIPD, visando o cumprimento dos princípios da segurança e da prevenção, nos termos do art. 6º, VII e VIII, da LGPD, a fim de implementar programa de governança em privacidade que, entre outros itens, deve estabelecer políticas e salvaguardas adequadas com base em processo de avaliação sistemática de impactos e riscos à privacidade, conforme determinação do art. 50, § 2º, I, d,d a LGPD.',
  'Operações de tratamento efetuadas para fins exclusivos de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos responsáveis por operações de tratamento para fins de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais, a emissão do RIPD, nos termos do art. 4º,  § 3º, da LGPD.',
}

const RISK = [
  { id: 3, name: 'Alto' },
  { id: 4, name: 'Severo' },
  { id: 5, name: 'Alto e Severo' },
]

const DESCRIPTION_RIPD = {
  title: 'Relatório de Impacto à Proteção de Dados Pessoais (RIPD)',
  content:
    'Em geral, o Relatório de Impacto à Proteção de Dados Pessoais (RIPD) é ' +
    'um documento que estabelece a gestão de riscos da sua organização. Ele ' +
    'contém a descrição detalhada dos seus processos de tratamento de dados ' +
    'pessoais que possuem risco alto ou severo e quais medidas são adotadas ' +
    'para mitigação dos riscos. Pode haver exceções, como:',
  options: [
    'Hipótese de Legítimo Interesse;',
    'Outras obrigações legais;',
    'Segurança Pública;',
    'Entre outros.',
  ],
}

const DESCRIPTION_ROPA = {
  title: 'Registro das Atividades de Tratamento (RoPA)',
  content:
    'O Registro das Atividades de Tratamento (traduzido da sigla em inglês, ' +
    'Record of Processing Activities, RoPA, é um documento essencial do ' +
    'Regulamento Geral de Proteção de Dados (GDPR) da União Europeia. É ' +
    'um documento que detalha todas as atividades de processamento de dados ' +
    'pessoais realizadas por uma organização. O RoPA inclui informações ' +
    'sobre a finalidade do processamento, as categorias de dados pessoais ' +
    'envolvidos, os enquadramentos legais, os destinatários dos dados, os ' +
    'prazos de retenção dos dados e as medidas de segurança implementadas, ' +
    'dentre outras informações. O RoPA desempenha um papel fundamental na ' +
    'conformidade com o GDPR, ajudando as organizações a demonstrar ' +
    'transparência e responsabilidade no tratamento de dados pessoais.',
  options: [],
}

const DESCRIPTION_RMC = {
  title: 'Relatório de RMC’s',
  content:
    'No contexto da LGPD, a melhoria contínua implica que as organizações devem sempre buscar aprimorar seus processos, políticas e medidas de segurança para garantir conformidade com a lei e proteção adequada dos dados pessoais. Isso envolve revisões regulares de políticas, implementação de medidas de segurança adicionais, atualização de procedimentos, treinamentos periódicos e adoção de tecnologias avançadas. ',
  options: [],
}

const DESCRIPTION_ACCOUNTABILITY = {
  title: 'Relatório de Prestação de contas',
  content:
    'O relatório de declaração de prestação de contas tem como objetivo demonstrar que o agente adotou medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais. Ele estabelece um paralelo entre as funcionalidades da plataforma e os requisitos da Lei de Proteção de Dados.',
  options: [],
}

const DESCRIPTION_MANAGEMENT = {
  title: 'Relatório Gerencial',
  content:
    'O relatório gerencial é um resumo de informações-chave que fornece uma visão geral do desempenho da organização, auxiliando a alta administração na tomada de decisões estratégicas e operacionais.',
  options: [],
}

const DESCRIPTION_CONTROL = {
  title: 'Relatório de Medidas de Segurança',
  content:
    'O relatório de medidas de segurança avalia se a organização cumpre regulamentações e políticas estabelecidas, identificando áreas de risco e oportunidades de melhoria. Essencial para garantir transparência e conformidade legal. Ele é gerado com base nos questionários respondidos pela organização e nas medidas de segurança que passarão a ser adotados após a realização do plano de ação.',
  options: [],
}

const DESCRIPTION_FRAGILITY = {
  title: 'Relatório de Ameaças',
  content:
    'Este relatório proporciona uma visão abrangente das ameaças presentes na organização, detalhando suas medidas de segurança e os locais em que podem ocorrer.',
  options: [],
}

const DESCRIPTION_TRAINING = {
  title: 'Relatório de Treinamentos',
  content:
    'O relatório de treinamento é um documento sucinto que permite acompanhar a evolução da conscientização dos colaboradores da organização. Ele é uma ferramenta valiosa para identificar bons e maus resultados.',
}

const DESCRIPTION_LIA = {
  title: 'Relatório de Legítimo Interesse',
  content:
    'Um relatório de legítimo interesse é um documento que descreve detalhadamente as razões pelas quais uma organização considera que o processamento de dados pessoais é justificado com base no princípio do legítimo interesse.',
}

const DESCRIPTION_CONSENT = {
  title: 'Relatório de Consentimentos',
  content:
    'O relatório de consentimento é um documento que formaliza a autorização ou o acordo de uma pessoa para participar em uma atividade, projeto ou processo, especialmente em contextos onde a privacidade, a segurança ou a ética são preocupações importantes.',
}

const DESCRIPTION_AUDIT = {
  title: 'Relatório de Auditoria',
  content:
    'O relatório de auditoria é essencial para identificar quais processos foram atualizados e quais estão desatualizados durante a auditoria.',
}

const DESCRIPTION = {
  accountability: DESCRIPTION_ACCOUNTABILITY,
  audit: DESCRIPTION_AUDIT,
  consent: DESCRIPTION_CONSENT,
  control: DESCRIPTION_CONTROL,
  fragility: DESCRIPTION_FRAGILITY,
  lia: DESCRIPTION_LIA,
  management: DESCRIPTION_MANAGEMENT,
  ripd: DESCRIPTION_RIPD,
  rmc: DESCRIPTION_RMC,
  ropa: DESCRIPTION_ROPA,
  training: DESCRIPTION_TRAINING,
}

const CHARACTER_LIMIT = 500

const KIND = {
  ROPA: 'ropa',
  COMPLIANCE: 'compliance',
  RIPD: 'ripd',
  FRAGILITY: 'fragility',
  TRAINING: 'training',
  LIA: 'lia',
  AUDIT: 'audit',
}

const REPORT_CONSENT_LABELS = {
  reportByReceipt: 'Recibo de Consentimento',
  reportByPeople: 'Relatório de Consentimentos (Por pessoa)',
  reportByForm: 'Relatório de Consentimentos (Por formulário)',
  reportComplete: 'Relatório de Consentimentos (Completo)',
}

const TYPES_CONSENTS = {
  RECEIPT_FORM: 'reportByForm',
  RECEIPT_CONSENT: 'reportByReceipt',
  RECEIPT_PERSONAL: 'reportByPeople',
  RECEIPT_COMPLETE: 'reportComplete',
}

const RIPD_DRIVER_STEP_TOUR = 'ripd-driver-step-tour'
const RIPD_DRIVER_STEP_BUTTON = 'ripd-driver-step-button'

const RIPD_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + RIPD_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O Relário RIPD é exigido pela LGPD para atividades de tratamento de dados com risco aos direitos individuais. O relatório contém detalhes cruciais sobre essas atividades, controles de proteção de dados e planos de ação, e deve ser apresentado em auditorias, para diretores, lideres ou para quem deseja saber sobre os riscos da organização.',
    },
  },
  {
    element: '#' + RIPD_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO! O RIPD estabelece a gestão de riscos altos ou severos dentro de uma organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'",
    },
  },
]

const ROPA_DRIVER_STEP_TOUR = 'ropa-driver-step-tour'
const ROPA_DRIVER_STEP_BUTTON = 'ropa-driver-step-button'

const ROPA_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + ROPA_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório RoPA deve ser apresentado a quem deseja saber informações mais detalhadas referentes aos riscos e registros das operações de tratamento de dados pessoais mantidos pelos controladores e operadores, especialmente quando baseados no legítimo interesse.',
    },
  },
  {
    element: '#' + ROPA_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO! O RoPA detalha todas as atividades de processamento de dados pessoais de uma organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'",
    },
  },
]

const ACCOUNTABILITY_DRIVER_STEP_TOUR = 'accountability-driver-step-tour'
const ACCOUNTABILITY_DRIVER_STEP_BUTTON = 'accountability-driver-step-button'

const ACCOUNTABILITY_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + ACCOUNTABILITY_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de prestação de contas é um documento que comprova a observância e o cumprimento das normas de proteção de dados pessoais e,inclusive, da eficácia dessas medidas,deve ser apresentado em auditorias, para diretores, lideres ou para quem o solicitar de acordo com a necessidade.',
    },
  },
  {
    element: '#' + ACCOUNTABILITY_DRIVER_STEP_BUTTON,
    popover: {
      description:
        'Gere seu RELATÓRIO! O relatório de declaração de prestação de contas visa demonstrar que o agente implementou medidas eficazes para garantir a conformidade com as normas de proteção de dados pessoais.',
    },
  },
]

const MANAGEMENT_DRIVER_STEP_TOUR = 'management-driver-step-tour'
const MANAGEMENT_DRIVER_STEP_BUTTON = 'management-driver-step-button'

const MANAGEMENT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + MANAGEMENT_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório gerencial é destinado àqueles interessados nos dados relativos ao desempenho da organização dentro plataforma, os quais são apresentados por meio de gráficos. Pode ser requisitado em auditorias, por diretores, líderes ou qualquer pessoa conforme necessário.',
    },
  },
  {
    element: '#' + MANAGEMENT_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO, ele fornece uma visão geral do desempenho da organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'",
    },
  },
]

const CONTROL_DRIVER_STEP_TOUR = 'control-driver-step-tour'
const CONTROL_DRIVER_STEP_BUTTON = 'control-driver-step-button'

const CONTROL_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + CONTROL_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de medidas de segurança deve ser disponibilizado para aqueles interessados nos questionários, controles e planos de ação relacionados à proteção de dados. Pode ser requisitado em auditorias, por diretores, líderes ou qualquer pessoa conforme necessário, promovendo transparência e garantindo a conformidade com as normas de segurança de dados.',
    },
  },
  {
    element: '#' + CONTROL_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO! O relatório de medidas de segurança avalia o cumprimento das regulamentações e políticas estabelecidas pela organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'",
    },
  },
]

const FRAGILITY_DRIVER_STEP_TOUR = 'fragility-driver-step-tour'
const FRAGILITY_DRIVER_STEP_BUTTON = 'fragility-driver-step-button'

const FRAGILITY_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + FRAGILITY_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de ameaças deve ser apresentado a quem deseja conhecer as ameaças presentes na organização, detalhando as medidas de segurança adotadas e os locais de ocorrência. Pode ser solicitado em auditorias, por diretores, líderes ou qualquer pessoa conforme necessário, promovendo transparência e assegurando a conformidade com as normas de segurança de dados.',
    },
  },
  {
    element: '#' + FRAGILITY_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO para uma visão completa das ameaças na organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'",
    },
  },
]

const TRAINING_DRIVER_STEP_TOUR = 'training-driver-step-tour'
const TRAINING_DRIVER_STEP_BUTTON = 'training-driver-step-button'

const TRAINING_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + TRAINING_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de treinamentos deve ser apresentado a quem deseja saber quais departamentos tem a maior porcentagem de colaboradores certificados com métricas por departamento e listagens dos usuários. Pode ser requisitado em auditorias, por diretores, líderes ou qualquer pessoa conforme necessário.',
    },
  },
  {
    element: '#' + TRAINING_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Gere seu RELATÓRIO para acompanhar a evolução da conscientização dos colaboradores da organização. Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova",
    },
  },
]

const LIA_DRIVER_STEP_TOUR = 'lia-driver-step-tour'
const LIA_DRIVER_STEP_BUTTON = 'lia-driver-step-button'

const LIA_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + LIA_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de legítimo interesse deve ser apresentado a quem deseja saber informações mais detalhadas referentes aos riscos e registros das operações de tratamento de dados pessoais baseados no legítimo interesse.',
    },
  },
  {
    element: '#' + LIA_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Um relatório de legítimo interesse é um documento que detalha as razões pelas quais uma organização acredita que o processamento de dados pessoais é justificado com base no princípio do legítimo interesse. Após gerar o relatório, veja suas versões abaixo. As versões geradas serão registradas mensalmente. Se necessário, realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'.",
    },
  },
]

const CONSENT_DRIVER_STEP_TOUR = 'consent-driver-step-tour'
const CONSENT_DRIVER_STEP_BUTTON = 'consent-driver-step-button'

const CONSENT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + CONSENT_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O relatório de consentimento é um documento que formaliza a autorização ou o acordo de uma pessoa para participar em uma atividade, projeto ou processo.',
    },
  },
  {
    element: '#' + CONSENT_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Um relatório de consentimento é um documento que detalha os recibos gerados em atividades, projetos ou processos. É possível baixar um único recibo, todos os recibos de um único formulário, todos os recibos de uma pessoa e todos os recibos de todos os formulários. Após gerar o relatório, veja suas versões abaixo. As versões geradas serão registradas mensalmente. Se necessário, realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'.",
    },
  },
]

const AUDTT_DRIVER_STEP_TOUR = 'audit-driver-step-tour'
const AUDIT_DRIVER_STEP_BUTTON = 'audit-driver-step-button'

const AUDIT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + AUDTT_DRIVER_STEP_TOUR,
    popover: {
      description:
        'O Relatório de Auditoria oferece uma visão abrangente dos processos auditados, tanto em auditorias específicas quanto em todas as realizadas. Ele apresenta métricas sobre o total de processos auditados, destacando quais estão atualizados ou desatualizados, e identifica os responsáveis de cada setor.',
    },
  },
  {
    element: '#' + AUDIT_DRIVER_STEP_BUTTON,
    popover: {
      description:
        "Após gerar o relatório, veja suas versões abaixo. As versões geradas ficarão registradas mensalmente. Caso necessário realize modificações, gere um novo relatório e escolha 'Excluir versão atual e gerar uma nova'.",
    },
  },
]

const REPORTS_DRIVER_TYPE_OBJECT = {
  ripd: {
    object: RIPD_DRIVER_STEPS_OBJECT,
    tour: RIPD_DRIVER_STEP_TOUR,
    button: RIPD_DRIVER_STEP_BUTTON,
  },
  ropa: {
    object: ROPA_DRIVER_STEPS_OBJECT,
    tour: ROPA_DRIVER_STEP_TOUR,
    button: ROPA_DRIVER_STEP_BUTTON,
  },
  accountability: {
    object: ACCOUNTABILITY_DRIVER_STEPS_OBJECT,
    tour: ACCOUNTABILITY_DRIVER_STEP_TOUR,
    button: ACCOUNTABILITY_DRIVER_STEP_BUTTON,
  },
  management: {
    object: MANAGEMENT_DRIVER_STEPS_OBJECT,
    tour: MANAGEMENT_DRIVER_STEP_TOUR,
    button: MANAGEMENT_DRIVER_STEP_BUTTON,
  },
  control: {
    object: CONTROL_DRIVER_STEPS_OBJECT,
    tour: CONTROL_DRIVER_STEP_TOUR,
    button: CONTROL_DRIVER_STEP_BUTTON,
  },
  fragility: {
    object: FRAGILITY_DRIVER_STEPS_OBJECT,
    tour: FRAGILITY_DRIVER_STEP_TOUR,
    button: FRAGILITY_DRIVER_STEP_BUTTON,
  },
  training: {
    object: TRAINING_DRIVER_STEPS_OBJECT,
    tour: TRAINING_DRIVER_STEP_TOUR,
    button: TRAINING_DRIVER_STEP_BUTTON,
  },
  lia: {
    object: LIA_DRIVER_STEPS_OBJECT,
    tour: LIA_DRIVER_STEP_TOUR,
    button: LIA_DRIVER_STEP_BUTTON,
  },
  consent: {
    object: CONSENT_DRIVER_STEPS_OBJECT,
    tour: CONSENT_DRIVER_STEP_TOUR,
    button: CONSENT_DRIVER_STEP_BUTTON,
  },
  audit: {
    object: AUDIT_DRIVER_STEPS_OBJECT,
    tour: AUDTT_DRIVER_STEP_TOUR,
    button: AUDIT_DRIVER_STEP_BUTTON,
  },
}

export default {
  CHARACTER_LIMIT,
  DESCRIPTION,
  JUSTIFICATION_LABEL,
  JUSTIFICATION,
  KIND,
  RISK,
  REPORT_CONSENT_LABELS,
  REPORTS_DRIVER_TYPE_OBJECT,
  TYPES_CONSENTS,
}
