import { isEmpty } from 'lodash'

import components from '../../components'

import constants from 'constants/index'
import helpers from 'helpers'
import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, GAP, PADDING } from '../../constants'

const actionPlans = (pdf, date, securityMeasuresData) => {
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2
  const columStyles = {
    0: {
      halign: 'left',
    },
  }

  const plansColumStyles = {
    0: {
      cellWidth: WIDTH * 0.3,
      halign: 'left',
      font: 'Roboto-Bold',
    },
  }

  const mountedSecurityMeasures = {}

  securityMeasuresData.forEach((securityMeasure) => {
    const { questionnaireKind } = securityMeasure
    if (!mountedSecurityMeasures[questionnaireKind]) {
      mountedSecurityMeasures[questionnaireKind] = []
    }
    mountedSecurityMeasures[questionnaireKind].push(securityMeasure)
  })

  const generatePlanRows = (plan) =>
    isEmpty(plan)
      ? [
          [
            'Esta medida de segurança ainda não tem um plano de ação cadastrado.',
          ],
        ]
      : [
          [
            'Status',
            helpers.questionControls.status(plan.status, true) || 'N/A',
          ],
          ['Responsável', plan.responsibleUser?.name || 'N/A'],
          ['Data de início', helpers.formatters.date(plan.startDate) || 'N/A'],
          ['Prazo final', helpers.formatters.date(plan.deadlineDate) || 'N/A'],
          ['O que', plan.whatQuestion || 'N/A'],
          ['Porquê', plan.becauseQuestion || 'N/A'],
          ['Como', plan.howQuestion || 'N/A'],
          ['Onde', plan.whereQuestion || 'N/A'],
          [
            'Quanto',
            plan.howPriceQuestion
              ? helpers.formatters.numberToPrice(plan.howPriceQuestion)
              : 'N/A',
          ],
          ['Evidências', plan.evidence || 'N/A'],
        ]

  const securityMeasureKeys = Object.keys(mountedSecurityMeasures)

  pdf.addPage()
  components.numberPage(pdf, palette.black)
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Planos de Ação', GAP)

  securityMeasureKeys.forEach((securityMeasureKind, index) => {
    const measures = mountedSecurityMeasures[securityMeasureKind]

    let startY = index === 0 ? GAP * 1.25 : pdf.lastAutoTable.finalY + 16

    measures.forEach((securityMeasure) => {
      const actionPlans = securityMeasure.questionPlanActionEvidencies

      actionPlans.forEach((actionPlan) => {
        if (
          actionPlan.status !== constants.questionControl.INITIAL_PLAN_STATUS
        ) {
          components.table(
            pdf,
            [[securityMeasure.title]],
            [[securityMeasure.subtitle]],
            startY,
            0.5,
            PADDING,
            true,
            columStyles,
          )

          components.table(
            pdf,
            null,
            generatePlanRows(actionPlan),
            pdf.lastAutoTable.finalY,
            0.5,
            PADDING,
            true,
            isEmpty(actionPlan) ? columStyles : plansColumStyles,
          )

          startY = pdf.lastAutoTable.finalY + 16

          components.lineWithDate(pdf, date)
          components.numberPage(pdf, palette.black)
        }
      })
    })
  })
}

export default actionPlans
