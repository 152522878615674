import { sum } from 'lodash'

import templates from '../../templates'
import components from '../../components'

import { WIDTH as WIDTHPdf, GAP, PADDING, fontSize } from '../../constants'

import {
  ratio,
  convertObjectToCanvas,
  calculateAspectRatioFit,
} from '../../helpers'

import palette from 'theme/palette'
import constants from 'constants/index'

const diagnostics = async ({
  pdf,
  date,
  gapStats,
  customStyles,
  fragilityStats,
  customThemeStatus,
}) => {
  const skippedPages = customThemeStatus ? 1 : 3

  const WIDTH = WIDTHPdf(pdf)

  const { fragilities: fragilitiesIds, securityMeasures: securityMeasuresIds } =
    constants.myLgpd.GRAPH_IDS

  const totalAnswered = sum(
    gapStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.asweredQuestions || 0,
    ),
  )
  const totalQuestion = sum(
    gapStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.totalQuestions || 0,
    ),
  )
  const pendings = totalQuestion - totalAnswered

  templates.chapterCover(pdf, 'DIAGNÓSTICO', '', customStyles)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '4', 'Diagnóstico')

  components.paragraph(
    pdf,
    'A seção "Diagnóstico" compreende um conjunto integrado de ferramentas destinadas a avaliar e fortalecer a segurança de dados das organizações em conformidade com a LGPD. Os questionários permitem uma análise abrangente das práticas de segurança existentes, enquanto as medidas de segurança propostas são personalizadas com base nas respostas dos questionários para abordar áreas de vulnerabilidade específicas.\n\nEssas medidas visam mitigar ameaças potenciais à segurança de dados, desde o acesso não autorizado até violações de privacidade, oferecendo às organizações uma estrutura sólida para garantir a conformidade contínua com os regulamentos de proteção de dados.',
    GAP * 3.25,
    palette.subscription.primary.main,
  )

  components.subtitle(pdf, 'Questionários', GAP * 5.25)

  components.paragraph(
    pdf,
    'Os questionários, no contexto da LGPD, são instrumentos utilizados para coletarinformações e avaliar a conformidade de uma organização com os requisitosestabelecidos pela lei. Esses questionários podem ser estruturados e abordar diferentesaspectos relacionados à proteção de dados, como políticas de privacidade, medidas desegurança, consentimento dos titulares dos dados e práticas de tratamento deinformações pessoais.\n\nAo responder aos questionários, as organizações podem identificar lacunas deconformidade, áreas que requerem melhorias e oportunidades para fortalecer suaspráticas de proteção de dados. Os questionários ajudam as organizações a avaliar seunível de conformidade com a LGPD, implementar ações corretivas e demonstrar seucompromisso em proteger a privacidade e os direitos dos titulares de dados',
    GAP * 5.5,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 7.75,
    ratio(720),
    palette.dashboard.chart.primary.approved,
    ratio,
    'Questionários respondidos',
    totalAnswered.toString(),
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 7.75,
    ratio(720),
    palette.statuses.disapproved,
    ratio,
    'Questionários não respondidos',
    pendings.toString(),
  )

  components.numberPage(pdf, palette.black, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  var mountQuestionnaireRow = (questionnaire) => [
    [
      'Progresso de resolução do questionário ',
      Math.round(
        (questionnaire.asweredQuestions / questionnaire.totalQuestions) * 100,
      ).toString() + '%',
    ],
    [
      'Conformidade do questionário',
      Math.round(questionnaire.complianceGap).toString() + '%',
    ],
  ]

  gapStats.questionnaires.forEach((questionnaire, index) => {
    let startY = index === 0 ? GAP : pdf.autoTable.previous.finalY + 16

    components.table(
      pdf,
      [[questionnaire.name, 'Total (%)']],
      mountQuestionnaireRow(questionnaire),
      startY,
      0.5,
      PADDING,
      true,
    )

    components.lineWithDate(pdf, date)
    components.numberPage(pdf, palette.primary.main, false, skippedPages)
  })

  components.numberPage(pdf, palette.primary.main, false, skippedPages)

  let currentY = pdf.autoTable.previous.finalY + 32

  components.subtitle(pdf, 'Medidas de Segurança', currentY)

  components.paragraph(
    pdf,
    'Medidas de segurança são ações implementadas para proteger ativos, dados ou sistemas contra ameaças potenciais. Isso pode incluir restrição de acesso, criptografia, backup regular de dados, uso de firewalls e antivírus, monitoramento de atividades suspeitas e implementação de políticas de segurança.\n\nEssas medidas visam reduzir o risco de danos, perdas ou acesso não autorizado, garantindo a integridade, confidencialidade e disponibilidade das informações.',
    currentY + GAP * 0.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    pdf.autoTable.previous.finalY + 32 + GAP,
    WIDTH - PADDING * 2,
    palette.primary.main,
    ratio,
    'Total de medidas adotadas',
    fragilityStats?.securityMeasures.toString(),
  )

  pdf.addPage()

  components.lineWithDate(pdf, date)

  const securityMeasureByCategory = await convertObjectToCanvas(
    `apexcharts${securityMeasuresIds.statusesByCategories}`,
  )

  const securityMeasureByCategoryProportions = calculateAspectRatioFit(
    securityMeasureByCategory.width,
    securityMeasureByCategory.height,
    WIDTH - PADDING * 2,
    165,
  )

  components.text({
    pdf,
    sub: 'Status de medidas por categoria',
    positionY: GAP,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  pdf.addImage(
    securityMeasureByCategory.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 1.25,
    securityMeasureByCategoryProportions.width,
    securityMeasureByCategoryProportions.height,
  )

  const securityMeasureStatuses = await convertObjectToCanvas(
    securityMeasuresIds.statuses,
  )

  const securityMeasureStatusesProportions = calculateAspectRatioFit(
    securityMeasureStatuses.width,
    securityMeasureStatuses.height,
    WIDTH - PADDING * 2,
    165,
  )

  pdf.addImage(
    securityMeasureStatuses.toDataURL('image/png'),
    'PNG',
    (WIDTH - PADDING * 2) / 3,
    GAP * 3.5,
    securityMeasureStatusesProportions.width,
    securityMeasureStatusesProportions.height,
  )

  components.subtitle(pdf, 'Ameaças', GAP * 6.75)

  components.paragraph(
    pdf,
    'Ameaças à segurança da informação são potenciais eventos ou circunstâncias que podem comprometer a integridade, confidencialidade ou disponibilidade de dados e sistemas. Essas ameaças podem surgir de várias fontes, como ataques cibernéticos, desastres naturais, falhas de software, erro humano e ameaças internas.\n\nPara mitigar esses riscos, as organizações devem identificar e avaliar regularmente as ameaças, implementando medidas de segurança adequadas, como firewalls, antivírus, backups regulares, treinamento de funcionários e políticas de acesso restrito, visando proteger seus ativos e dados contra potenciais danos ou perdas.',
    GAP * 7,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 8.5,
    WIDTH - PADDING * 2,
    palette.primary.main,
    ratio,
    'Total de ameaças',
    fragilityStats?.fragility?.totalCount.toString(),
  )

  components.numberPage(pdf, palette.primary.main, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.text({
    pdf,
    sub: 'Ameaças com medidas vinculadas',
    positionY: GAP,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const fragilitiesWithLinkedMeasures = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.withSecurityMeasures}`,
  )

  const fragilitiesWithLinkedMeasuresProportions = calculateAspectRatioFit(
    fragilitiesWithLinkedMeasures.width,
    fragilitiesWithLinkedMeasures.height,
    (WIDTH - PADDING * 2) / 2,
    165,
  )

  pdf.addImage(
    fragilitiesWithLinkedMeasures.toDataURL('image/png'),
    'PNG',
    PADDING / 2,
    GAP * 1.25,
    fragilitiesWithLinkedMeasuresProportions.width,
    fragilitiesWithLinkedMeasuresProportions.height,
  )

  const fragilitiesBySeverity = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.bySeverities}`,
  )

  const fragilitiesBySeverityProportions = calculateAspectRatioFit(
    fragilitiesBySeverity.width,
    fragilitiesBySeverity.height,
    (WIDTH - PADDING * 2) / 2,
    165,
  )

  components.text({
    pdf,
    sub: ['Distribuição de ameaças por grau', 'de severidade'],
    positionY: GAP,
    positionX: fragilitiesWithLinkedMeasuresProportions.width + PADDING * 1.3,
    customFontSize: fontSize.paragraph,
  })

  pdf.addImage(
    fragilitiesBySeverity.toDataURL('image/png'),
    'PNG',
    fragilitiesWithLinkedMeasuresProportions.width + PADDING,
    GAP * 1.25,
    fragilitiesBySeverityProportions.width,
    fragilitiesBySeverityProportions.height,
  )

  components.text({
    pdf,
    sub: 'Departamentos por maior quantidade de ameaças',
    positionY: GAP * 3.75,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const departmentsHighestOfFragilities = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.departmentsWithHighestFragilities}`,
  )

  const departmentsHighestOfFragilitiesProportions = calculateAspectRatioFit(
    departmentsHighestOfFragilities.width,
    departmentsHighestOfFragilities.height,
    WIDTH - PADDING * 2,
    200,
  )

  pdf.addImage(
    departmentsHighestOfFragilities.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 3.85,
    departmentsHighestOfFragilitiesProportions.width,
    departmentsHighestOfFragilitiesProportions.height,
  )

  components.text({
    pdf,
    sub: 'Departamentos por menor quantidade de ameaças',
    positionY: GAP * 6.85,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const departmentsLowestOfFragilities = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.departmentsWithLowestFragilities}`,
  )

  const departmentsLowestOfFragilitiesProportions = calculateAspectRatioFit(
    departmentsLowestOfFragilities.width,
    departmentsLowestOfFragilities.height,
    WIDTH - PADDING * 2,
    200,
  )

  pdf.addImage(
    departmentsLowestOfFragilities.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 7,
    departmentsLowestOfFragilitiesProportions.width,
    departmentsLowestOfFragilitiesProportions.height,
  )

  components.numberPage(pdf, palette.primary.main, false, skippedPages)

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.text({
    pdf,
    sub: 'Departamentos por menor severidade de ameaças',
    positionY: GAP,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const departmentsLowestOfSeverities = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.departmentsWithLowestSeverities}`,
  )

  const departmentsLowestOfSeveritiesProportions = calculateAspectRatioFit(
    departmentsLowestOfSeverities.width,
    departmentsLowestOfSeverities.height,
    WIDTH - PADDING * 2,
    200,
  )

  pdf.addImage(
    departmentsLowestOfSeverities.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 1.25,
    departmentsLowestOfSeveritiesProportions.width,
    departmentsLowestOfSeveritiesProportions.height,
  )

  components.text({
    pdf,
    sub: 'Departamentos por maior severidade de ameaças',
    positionY: GAP * 4.25,
    positionX: PADDING,
    customFontSize: fontSize.paragraph,
  })

  const departmentsHighestOfSeverities = await convertObjectToCanvas(
    `apexcharts${fragilitiesIds.departmentsWithHighestSeverities}`,
  )

  const departmentsHighestOfSeveritiesProportions = calculateAspectRatioFit(
    departmentsHighestOfSeverities.width,
    departmentsHighestOfSeverities.height,
    WIDTH - PADDING * 2,
    200,
  )

  pdf.addImage(
    departmentsHighestOfSeverities.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 4.4,
    departmentsHighestOfSeveritiesProportions.width,
    departmentsHighestOfSeveritiesProportions.height,
  )

  components.numberPage(pdf, palette.primary.main, false, skippedPages)
}

export default diagnostics
