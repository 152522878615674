import components from '../../components'

import palette from 'theme/palette'
import { PADDING, GAP, defaultColumStyles } from '../../constants'
import helpers from '../helpers'

const generalInformation = async ({ pdf, date, customThemeStatus, users }) => {
  pdf.addPage('a4', 'p')
  components.subtitle(pdf, 'Treinamentos', GAP)

  const headerStyles = {
    textColor: palette.white,
    font: 'Roboto-Bold',
    fillColor: palette.primary.main,
    cellPadding: 6,
  }

  users.forEach((user, index) => {
    let startY = index === 0 ? GAP * 1.25 : pdf.autoTable.previous.finalY + 16

    components.lineWithDate(pdf, date)

    components.table(
      pdf,
      helpers.mountUserHeader(user),
      helpers.mountUserRow(user),
      startY,
      0.5,
      PADDING,
      true,
      defaultColumStyles(0.5, pdf),
      headerStyles,
    )

    components.numberPage(pdf, palette.primary.main, customThemeStatus)
  })
}

export default generalInformation
