import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Search as SearchIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Button, TextField } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import schema from './schema'
import useStyles from './styles'

const SearchForm = ({ setKeyword }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setKeyword(data?.name)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu uma falha ao tentar realizar a pesquisa!',
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex">
        <Controller
          as={
            <TextField
              placeholder="Pesquisa"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon className={classes.searchIcon} />,
              }}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              fullWidth
            />
          }
          control={control}
          name="name"
          mode="onChange"
        />
        <Box ml={2}>
          <Button
            variant="contained"
            className={classes.searchButton}
            type="submit"
          >
            Pesquisar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

SearchForm.propTypes = {
  setKeyword: PropTypes.func,
}

export default SearchForm
