import templates from '../../templates'
import components from '../../components'
import dashboardComponents from '../components'

import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, HEIGHT as HEIGHTPdf, GAP } from '../../constants'

const suppliers = async (
  pdf,
  date,
  supplierStats,
  customStyles,
  customThemeStatus,
) => {
  const skippedPages = customThemeStatus ? 1 : 3

  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  templates.chapterCover(
    pdf,
    'FORNECEDORES',
    'Síntese das principais informações de seus fornecedores',
    customStyles,
  )

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '6', 'Fornecedores')

  components.paragraph(
    pdf,
    'De acordo com a LGPD, fornecedores são empresas ou entidades terceirizadas que prestam serviços ou fornecem produtos para uma organização. Esses fornecedores podem ter acesso a dados pessoais durante o cumprimento de suas atividades contratadas, seja como processadores de dados ou controladores conjuntos, dependendo do acordo estabelecido.\n\nA LGPD estabelece que as organizações devem garantir que seus fornecedores também estejam em conformidade com a legislação de proteção de dados, adotando medidas contratuais e técnicas para garantir a segurança e a privacidade dos dados pessoais compartilhados.\n\nAs organizações são responsáveis por selecionar fornecedores confiáveis e estabelecer acordos adequados para proteger os direitos dos titulares dos dados e garantir o cumprimento da LGPD em todas as etapas do processamento de dados.',
    GAP * 3.25,
    palette.subscription.primary.main,
  )

  dashboardComponents.biggerSupplierBox(
    pdf,
    GAP * 6,
    supplierStats.suppliers.length.toString(),
    supplierStats.providingCount.toString(),
  )

  components.numberPage(pdf, palette.white, false, skippedPages)

  pdf.addPage('a4', 'p')

  pdf.setFillColor(palette.primary.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  components.lineWithDate(pdf, date, palette.white)

  components.subtitle(
    pdf,
    'Conformidade de meus fornecedores',
    GAP,
    palette.white,
  )

  components.paragraph(
    pdf,
    'A imagem e tabela oferecem uma visão clara da distribuição dos fornecedores em relação à conformidade com a LGPD, permitindo uma rápida análise dos fornecedores que estão com um alto nível de conformidade (acima de 80%), aqueles que estão em um nível moderado de conformidade (entre 60% e 80%), bem como os fornecedores que exigem maior atenção e medidas de melhoria para atingir a conformidade adequada.\n\nEssa tabela auxilia na avaliação e gestão dos fornecedores, promovendo a conformidade com a LGPD e o fortalecimento da proteção de dados pessoais em todas as interações com terceiros.',
    GAP * 1.25,
    palette.white,
  )

  let under20 = 0
  let under40 = 0
  let under60 = 0
  let under80 = 0
  let over80 = 0

  // eslint-disable-next-line
  supplierStats.suppliers.map((supplier) => {
    supplier.compliance <= 20 && under20++
    supplier.compliance > 20 && supplier.compliance <= 40 && under40++
    supplier.compliance > 40 && supplier.compliance <= 60 && under60++
    supplier.compliance > 60 && supplier.compliance <= 80 && under80++
    supplier.compliance > 80 && over80++
  })

  dashboardComponents.complianceHexagons(
    pdf,
    under20.toString(),
    under40.toString(),
    under60.toString(),
    under80.toString(),
    over80.toString(),
  )

  components.numberPage(pdf, palette.white, false, skippedPages)

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'Conformidade de meus fornecedores', GAP)

  components.paragraph(
    pdf,
    'A análise dos fornecedores em relação à LGPD revela que alguns estão em baixa conformidade com os requisitos estabelecidos. Esses fornecedores requerem atenção especial e a implementação de medidas corretivas para melhorar sua conformidade com a LGPD. Essas ações visam reduzir os riscos de violação de dados e fortalecer a proteção dos dados pessoais em todas as interações com terceiros.',
    GAP * 1.25,
    palette.subscription.primary.main,
  )

  var suppliers = supplierStats?.suppliers
    ?.sort((a, b) => {
      const sumA = a.compliance
      const sumB = b.compliance
      return sumB + sumA
    })
    .map((supplier) => [
      supplier?.name,
      supplier?.id,
      Math.round(supplier?.compliance) + '%',
    ])

  components.table(
    pdf,
    [['Empresa', 'Identificador', 'Conformidade']],
    suppliers,
    GAP * 2.25,
    0.4,
  )

  components.numberPage(pdf, palette.primary.main, false, skippedPages)
}

export default suppliers
