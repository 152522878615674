import helpers from 'helpers'

import components from '../../components'

import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, GAP, PADDING } from '../../constants'

const securityMeasures = (pdf, date, customStyles, securityMeasuresData) => {
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2
  const columStyles = {
    0: {
      cellWidth: 0.25 * WIDTH,
      halign: 'left',
    },
    1: {
      cellWidth: 0.5 * WIDTH,
      halign: 'center',
    },
    2: {
      halign: 'center',
    },
    3: {
      halign: 'center',
    },
  }

  const mountedSecurityMeasures = {}

  for (let i = 0; i < securityMeasuresData.length; i++) {
    const securityMeasure = securityMeasuresData[i]
    const { questionnaireKind } = securityMeasure

    if (!mountedSecurityMeasures[questionnaireKind]) {
      mountedSecurityMeasures[questionnaireKind] = []
    }

    mountedSecurityMeasures[questionnaireKind].push(securityMeasure)
  }

  const generateRows = (array) => {
    array = array.map((securityMeasure) => [
      securityMeasure.title,
      securityMeasure.subtitle,
      securityMeasure.answer ?? '-',
      helpers.questionControls.status(securityMeasure.status, true),
    ])
    array.unshift(['Categoria', 'Medida de Segurança', 'Resposta', 'Status'])
    return array
  }

  const securityMeasureKeys = Object.keys(mountedSecurityMeasures)

  pdf.addPage()
  components.numberPage(pdf, palette.black)
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Medidas de Segurança', GAP)

  for (let index = 0; index < securityMeasureKeys.length; index++) {
    const securityMeasureKind = securityMeasureKeys[index]
    let startY = index === 0 ? GAP * 1.25 : pdf.autoTable.previous.finalY + 16

    components.lineWithDate(pdf, date)
    components.numberPage(pdf, palette.primary.main)

    components.table(
      pdf,
      [[securityMeasureKind, null, null, null]],
      generateRows(mountedSecurityMeasures[securityMeasureKind]),
      startY,
      0.2,
      PADDING,
      false,
      columStyles,
    )
  }
}

export default securityMeasures
